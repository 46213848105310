<template>
    <div class="app-container">
        <el-form label-position="right" label-width="80px" :model="login">
            <el-form-item label="用户名">
                <el-input v-model="login.username"></el-input>
            </el-form-item>
            <el-form-item label="密码">
                <el-input v-model="login.password"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="loginHandle()">登录</el-button>
            </el-form-item>
        </el-form>
        401
        <div ref='drap'>
            <el-tag v-for="tag in tags" :data-id='tag.name' :key="tag.id" closable style="margin-right:10px;">
                {{tag.name}}
            </el-tag>
        </div>

        <div class="box" ref="box">
            <div class="left">
            </div>
            <div class="resize" title="收缩侧边栏">
                ⋮
            </div>
            <div class="mid">
                <!--右侧div内容-->
                23
            </div>
        </div>
    </div>
</template>

<script>
    import Sortable from 'sortablejs';
    // import pdf from 'vue-pdf';
    import pdf from 'pdfobject'
    export default {
        name: 'err401',
        data() {
            return {
                login: {
                    username: '',
                    password: ''
                },
                tags: [{
                        id: 1,
                        name: '111'
                    },
                    {
                        id: 2,
                        name: '222'
                    },
                    {
                        id: 3,
                        name: '333'
                    },
                    {
                        id: 4,
                        name: '444'
                    },
                    {
                        id: 5,
                        name: '555'
                    }
                ],
                numPages: null,
                url:'/static/1.pdf'
            }
        },
        mounted() {
            this.dragControllerDiv();

        },
        created() {
            
            this.$nextTick(() => {
                let el = this.$refs.drap;
                let opt = {
                    animation: 1000,
                    //拖动结束
                    onEnd: function (evt) {
                        console.log(evt);
                        //获取拖动后的排序
                        var arr = sortable.toArray();
                        console.log(arr);
                    },
                };
                var sortable = Sortable.create(el, opt)
                pdf.embed(this.url, "#paperRaw");
            });
            

        },

        methods: {
             loginHandle() {
                let params = new FormData();
                params.append("username", this.login.username);
                params.append("password", this.login.password);
                this.$api.user.login(params).then(res => {
                    if (res.data.code == 200) {
                        this.$message.success("登录成功！");
                    }
                });
            },
            dragControllerDiv: function () {
                var resize = document.getElementsByClassName('resize');
                var left = document.getElementsByClassName('left');
                var mid = document.getElementsByClassName('mid');
                var box = document.getElementsByClassName('box');
                for (let i = 0; i < resize.length; i++) {
                    // 鼠标按下事件
                    resize[i].onmousedown = function (e) {
                        //颜色改变提醒
                        resize[i].style.background = '#818181';
                        var startX = e.clientX;
                        resize[i].left = resize[i].offsetLeft;
                        // 鼠标拖动事件
                        document.onmousemove = function (e) {
                            var endX = e.clientX;
                            var moveLen = resize[i].left + (endX -
                                startX); // （endx-startx）=移动的距离。resize[i].left+移动的距离=左边区域最后的宽度
                            var maxT = box[i].clientWidth - resize[i]
                                .offsetWidth; // 容器宽度 - 左边区域的宽度 = 右边区域的宽度

                            if (moveLen < 32) moveLen = 32; // 左边区域的最小宽度为32px
                            if (moveLen > maxT - 150) moveLen = maxT - 150; //右边区域最小宽度为150px

                            resize[i].style.left = moveLen; // 设置左侧区域的宽度

                            for (let j = 0; j < left.length; j++) {
                                left[j].style.width = moveLen + 'px';
                                mid[j].style.width = (box[i].clientWidth - moveLen - 10) + 'px';
                            }
                        };
                        // 鼠标松开事件
                        document.onmouseup = function (evt) {
                            //颜色恢复
                            resize[i].style.background = '#d6d6d6';
                            document.onmousemove = null;
                            document.onmouseup = null;
                            resize[i].releaseCapture && resize[i]
                                .releaseCapture(); //当你不在需要继续获得鼠标消息就要应该调用ReleaseCapture()释放掉
                        };
                        resize[i].setCapture && resize[i].setCapture(); //该函数在属于当前线程的指定窗口里设置鼠标捕获
                        return false;
                    };
                }
            },
        },
        components: {
            pdf
        }
    }
</script>

<style scoped>
    /* 拖拽相关样式 */
    /*包围div样式*/
    .box {
        width: 100%;
        height: 100%;
        margin: 1% 0px;
        overflow: hidden;
        box-shadow: -1px 9px 10px 3px rgba(0, 0, 0, 0.11);
    }

    /*左侧div样式*/
    .left {
        width: calc(32% - 10px);
        /*左侧初始化宽度*/
        height: 500px;
        background: #FFFFFF;
        float: left;
        overflow:auto;
    }

    /*拖拽区div样式*/
    .resize {
        cursor: col-resize;
        float: left;
        position: relative;
        top: 45%;
        background-color: #d6d6d6;
        border-radius: 5px;
        margin-top: 200px;
        width: 10px;
        height: 50px;
        background-size: cover;
        background-position: center;
        /*z-index: 99999;*/
        font-size: 32px;
        color: white;
    }

    /*拖拽区鼠标悬停样式*/
    .resize:hover {
        color: #444444;
    }

    /*右侧div'样式*/
    .mid {
        float: left;
        width: 68%;
        /*右侧初始化宽度*/
        height: 500px;
        background: #fff;
        /* box-shadow: -1px 4px 5px 3px rgba(0, 0, 0, 0.11); */
    }

    .paperRaw {
        height: 95vh;
        overflow: hidden;
    }
</style>